import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Navigate } from "react-router-dom";

interface Props {
  component: React.FC;
}
const ProtectedRoute: React.FC<Props> = ({ component }) => {
  const [isAuthenticated, setLoggedIn] = useState(true);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    let unmounted = false;

    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        if (unmounted) return;

        if (user && !unmounted) {
          setLoggedIn(true);
          setUser(user);
        } else {
          setUser(undefined);
          setLoggedIn(false);
        }
      } catch (e) {
        if (!unmounted) {
          setLoggedIn(false);
          setUser(undefined);
        }
      }
    })();

    return () => {
      unmounted = true;
    };
  }, []);

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return React.createElement(component, user);
};

export default ProtectedRoute;
