import React, { useRef } from "react";

interface IProps {
  src: string;
  onLoaded: () => void;
}

export function ResizableIframe(props: IProps) {
  const ref = useRef<HTMLIFrameElement>(null);

  return (
    <iframe
      ref={ref}
      onLoad={props.onLoaded}
      title="static iframe"
      id="static-iframe"
      src={props.src}
      width="100%"
      style={{
        minHeight: "600px",
        height: "100%",
      }}
      frameBorder="0"
      scrolling="auto"
    ></iframe>
  );
}
