import React from "react";
import { Button, styled } from "@mui/material";

interface IProps {
  children: React.ReactNode;
  disabled?: boolean;
  size?: "small" | "medium" | "large" | undefined;
  width?: string;
  themes?: "light" | "dark";
  onClick?: () => void;
}

export function AppButton(props: IProps) {
  return (
    <StyledButton
      variant="contained"
      type="submit"
      disabled={props.disabled}
      onClick={props.onClick}
      size={props.size}
      sx={{ width: props.width }}
      themes={props.themes}
    >
      {props.children}
    </StyledButton>
  );
}

const StyledButton = styled(Button)<{ themes?: "light" | "dark" }>`
  border-radius: "4px";
  background-color: ${(p) =>
    p.themes === "dark" ? "rgba(60, 84, 91, 1)" : "rgba(94, 158, 94, 1)"};
  margin-top: 5px;
  &:hover {
    background-color: ${(p) =>
      p.themes === "dark" ? "#888F85" : "rgba(41, 51, 41, 1)"};
  }
`;
