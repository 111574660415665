export const AWS_CONFIG = {
  REGION: process.env.REACT_APP_APP_REGION,
  ACCOUNT_ID: process.env.REACT_APP_AWS_ACCOUNT_ID,
  USER_ARN: process.env.REACT_APP_AWS_USER_ARN,
  ACCESS_KEY: process.env.REACT_APP_ACCESS_KEY,
  SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  COGNITO: {
    USER_POOL_ID: process.env.REACT_APP_USERPOOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USERPOOL_APP_CLIENT_ID,
  },
  QUICKSIGHT: {
    DASHBOARD_ID: process.env.REACT_APP_QS_DASHBOARD_ID,
  },
  S3: {
    BUCKET: process.env.REACT_APP_BUCKET,
    REPORT_CSV: process.env.REACT_APP_S3_REPORT_CSV,
    REPORT_PDF: process.env.REACT_APP_S3_REPORT_PDF,
  },
};
