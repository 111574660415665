import React from "react";
import { Stack, Typography } from "@mui/material";
import AWS from "aws-sdk";
import { AWS_CONFIG } from "../configs/aws";
import { AppButton } from "./Button";
import { Toast } from "./../utils/notifications";

AWS.config.update({
  accessKeyId: AWS_CONFIG.ACCESS_KEY,
  secretAccessKey: AWS_CONFIG.SECRET_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: AWS_CONFIG.S3.BUCKET },
  region: AWS_CONFIG.REGION,
});

interface IProps {
  clientName: string;
}

export function ActionButtons(props: IProps) {
  const reportPdf = AWS_CONFIG.S3.REPORT_PDF;
  const reportCsv = AWS_CONFIG.S3.REPORT_CSV;

  function getS3FileUrl(fileName: string) {
    const params = {
      Bucket: `${AWS_CONFIG.S3.BUCKET}/${props.clientName}`,
      Key: fileName,
      Expires: 60 * 5,
    };

    return myBucket.getSignedUrl("getObject", params);
  }

  function handleDownload(fileName?: string) {
    if (fileName) {
      const element = document.createElement("a");
      element.href = getS3FileUrl(fileName);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    } else {
      Toast("Error on file downloading", "Download Failed", "warning");
    }
  }

  return (
    <Stack
      direction="column"
      flex={1}
      spacing={2}
      alignItems="flex-end"
      sx={{ width: "100%" }}
    >
      <AppButton
        width="200px"
        onClick={() => handleDownload(reportPdf)}
        children={
          <>
            <Typography>Report (PDF)</Typography>
          </>
        }
      />
      <AppButton
        width="200px"
        onClick={() => handleDownload(reportCsv)}
        children={
          <>
            <Typography>Data (Excel)</Typography>
          </>
        }
      />
    </Stack>
  );
}
