import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import Login from "./components/Login";
import { AWS_CONFIG } from "./configs/aws";
import Dashboard from "./components/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";

Auth.configure({
  aws_cognito_region: AWS_CONFIG.REGION,
  aws_user_pools_id: AWS_CONFIG.COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: AWS_CONFIG.COGNITO.APP_CLIENT_ID,
});

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<Login />} />
        <Route path="/" element={<ProtectedRoute component={Dashboard} />} />
      </Routes>
    </Router>
  );
};

export default App;
