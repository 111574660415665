import React from "react";
import { Stack, Typography, Container } from "@mui/material";
import { Auth } from "aws-amplify";
import LockIcon from "@mui/icons-material/Lock";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import { AppButton } from "./Button";
import { ActionButtons } from "./ActionButtons";
import { QuickSightDashboard } from "./QuickSightDashboard";

const Dashboard: React.FC = (props: any) => {
  const loggedInUser = props;
  const navigate = useNavigate();
  const attributes = loggedInUser && Object(loggedInUser).attributes;
  const userName = `${attributes && attributes.given_name} ${
    attributes && attributes.family_name
  }`;
  const clientName = attributes && attributes["custom:client"];

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate("/signin");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container style={{ height: "100%", marginTop: 20 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderBottom: "1px solid #ccc", paddingBottom: "20px" }}
      >
        <Stack direction="row" flex={2} spacing={2} alignItems="center">
          <img style={{ padding: "6px", width: "6%" }} src="/logo.svg" alt="" />
          <Typography variant="h4" sx={{ color: "rgba(41, 51, 41, 1)" }}>
            PATHWAYS OPTIMISER
          </Typography>
        </Stack>
        <Stack direction="column">
          <Typography sx={{ fontSize: "15px", fontFamily: "Barlow", fontWeight: 400 }}>
            <LockIcon
              fontSize="small"
              sx={{ position: "relative", top: "3px" }}
            />{" "}
            Welcome, {userName}
          </Typography>
          <AppButton
            onClick={handleLogout}
            size="small"
            themes="dark"
            children={
              <>
                <ExitToAppIcon fontSize="small" />{" "}
                <Typography sx={{ fontSize: "14px", paddingLeft: "5px" }}>
                  Logout
                </Typography>
              </>
            }
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ marginTop: "40px", height: "100%" }}
      >
        <QuickSightDashboard />
        <ActionButtons clientName={clientName} />
      </Stack>
    </Container>
  );
};

export default Dashboard;
