import React from "react";
import {
  Stack,
  CircularProgress,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import { useInput } from "./../utils/forms";
import { Toast } from "./../utils/notifications";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { AppButton } from "./Button";

const SignIn: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");

  let unmounted = false;

  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.signIn(email, password).then(() => {
        if (unmounted) return;

        Toast("Success!", "Login Successfully", "success");
        navigate("/");
      });
    } catch (error) {
      if (!unmounted) {
        Toast("Invalid Username / Password!", "Login Failed", "danger");
        setLoading(false);
      }
    }

    return () => {
      unmounted = true;
    };
  };

  return (
    <Container style={{ height: "100%", marginTop: 200 }}>
      <Stack
        style={{ height: "100%" }}
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack spacing={2} flex={3} alignItems="center">
          <img src="/logo-with-title.svg" width="90%" alt="" />
          <Typography variant="h4" sx={{ color: "rgba(41, 51, 41, 1)" }}>
            PATHWAYS OPTIMISER
          </Typography>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: 40,
              width: "80%",
            }}
            onSubmit={handleSubmit}
          >
            <Stack direction="column" spacing={2} width="100%">
              <TextField
                label="Email"
                {...bindEmail}
                type="email"
                fullWidth
                size="small"
                required
              />
              <TextField
                label="Password"
                type="password"
                {...bindPassword}
                fullWidth
                size="small"
                required
              />

              <AppButton
                disabled={loading}
                children={
                  <>
                    {loading && (
                      <CircularProgress size={20} style={{ marginRight: 20 }} />
                    )}
                    <Typography
                      sx={{ fontSize: "14px", fontFamily: "Barlow", fontWeight: 400 }}
                    >
                      Login to Your Account
                    </Typography>
                  </>
                }
              />
            </Stack>
          </form>
        </Stack>
        <RightHandSide />
      </Stack>
    </Container>
  );
};

function RightHandSide() {
  return (
    <div>
      <img src="/why-aptonow-4.png" alt="" />
    </div>
  );
}

export default SignIn;
