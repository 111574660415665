import React, { useState } from "react";
import { LinearProgress, Stack } from "@mui/material";
import { AWS_CONFIG } from "../configs/aws";
import { ResizableIframe } from "./ResizableIframe";

export function QuickSightDashboard() {
  const [loaded, setLoaded] = useState(false);

  return (
    <Stack sx={{ textAlign: "center", paddingRight: "1rem" }} width="80%">
      <Stack>
        {!loaded && <LinearProgress color="success" />}
      </Stack>

      <ResizableIframe
        src={`https://ap-southeast-2.quicksight.aws.amazon.com/sn/embed/share/accounts/${AWS_CONFIG.ACCOUNT_ID}/dashboards/${AWS_CONFIG.QUICKSIGHT.DASHBOARD_ID}?directory_alias=aptonow-qs`}
        onLoaded={() => setLoaded(true)}
      />
    </Stack>
  );
}
